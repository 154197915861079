
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiCheckCircle } from '@mdi/js';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import TrophySvg from '@/components/svg/TrophySvg.vue';
import { PlanFeature } from '@/../types/interfaces';

@Component({
	components: { TrophySvg },
})
export default class SubscriptionPlanCard extends Mixins(VuetifyMixin){
	mdiCheckCircle = mdiCheckCircle;
	@Prop({ default: 'baColorSuperLightBlue' }) plan: SubscriptionPlanModel;
	@Prop({ default: 'baColorGray8' }) borderColor: string;
	@Prop({ default: false, type: Boolean }) loading: boolean;
	
	get IconColor(): string{
		if(this.ProfessionalPlan){
			return this.getColor('baColorVibrantBlue')
		}
		return this.getColor('baColorGray3');
	}
	get ProfessionalPlan(): boolean{
		return this.plan.complete;
	}
	get IsFree(): boolean{
		return this.plan.free;
	}
	get PlanFeatures(): PlanFeature[]{
		if(!this.plan) return [];
		return this.plan.featureList;
	}

	get PlanTitle(): string{
		return this.plan.title;
	}
	get PlanTitleAppend(): string | null{
		return this.plan.titleAppend;
	}
	get PlanSubtitle(): string | null{
		return this.plan.subtitle;
	}
	get PlanPrice(): string{
		return 'Free';
	}

	get BorderColor(): string{
		return this.getColor(this.borderColor);
	}
	get CardStyle(): Record<string,string>{
		if(this.ProfessionalPlan){
			return {
				'border': `1px solid ${this.BorderColor}`
			}
		}
		return {};
	}

}
