
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPencil, mdiClose } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import { AthleteProfileModel } from '../../models/athlete/AthleteProfileModel';
import SportCard from '../ui/SportCard.vue';
import { calculateAge, formatDatePretty } from '../../helpers';
import { SportName } from '../../../types/enums';
import * as Routes from '../../../types/constants/web_client_user.routes';
import { ContextMenuItem } from '../../../types/interfaces';
import { userStore } from '../../store';

@Component({
	components: { SportCard },
})
export default class ManageAthleteCard extends Mixins(VuetifyMixin) {

	@Prop({ type: Boolean, default: false }) myProfile: boolean;

	menuItems: ContextMenuItem[] = [
		{
			text: 'Edit',
			icon: mdiPencil,
			click: (): Promise<void> => this.editAthlete(this.athlete),
		},
		{
			text: 'Remove',
			icon: mdiClose,
			click: (): Promise<void> => this.confirmDeleteAthlete(),
		}
	];

	async editAthlete(athlete: AthleteProfileModel): Promise<void>{
		this.$router.push({
			name: Routes.AthleteSettingsAccessControlForm,
			params:{
				profileShortId: athlete.shortId,
				athleteId: athlete.id,
			}
		});
	}

	async deleteAthlete(athlete: AthleteProfileModel): Promise<void>{
		await userStore.removeAthleteProfileAccess({ athlete, userId: userStore.authId });
	}
	async confirmDeleteAthlete(): Promise<void>{
		console.log("Confirm Delete");
		this.confirmDelete = true;
	}
	get DeleteAthleteLoading(): boolean{
		return userStore.deleteAthleteProfileLoading;
	}

	confirmDelete: boolean = false;
	
	@Prop({ required: true }) athlete: AthleteProfileModel;

	get Sport(): SportName | undefined{
		if(this.athlete.sports[0] === undefined) return undefined;
		return this.athlete.sports[0].id;
	}
	get Position(): string | undefined{
		if(this.athlete.sports[0] === undefined) return undefined;
		return this.athlete.sports[0].primaryPosition;
	}
	get Under13(): boolean{
		return this.Age < 13;
	}
	get Over18(): boolean{
		return this.Age > 18;
	}
	get Age(): number{
		const { years } = calculateAge(new Date(this.athlete.birthDate));
		return years;
	}
	get HasDob(): boolean{
		return !!this.athlete.birthDate;
	}
	get DateOfBirth(): string{
		if(this.HasDob){
			return formatDatePretty(new Date(this.athlete.birthDate));
		}
		return '';
	}
	get AgeLabel(): string{
		if(!this.Over18){
			return 'Child';
		}
		return 'Adult';
	}
	get AgeLabelColor(): string{
		if(this.AgeLabel === 'Child'){
			return this.getColor('baColorAccentGold');
		}
		return this.getColor('baColorGreen1');
	}

	get AvatarSize(): {
		size: number,
		borderSize: number,
		}{
		if(this.IsMobile){
			return {
				size: 75,
				borderSize: 3,
			};
		}
		return {
			size: 110,
			borderSize: 6,
		};
	}
	get ImpersonateAthleteProfileRoute(): string{
		return Routes.AthleteProfile;
	}

	goToProfile(athlete: AthleteProfileModel): void{
		if(this.myProfile){
			this.$router.push({
				name: Routes.AthleteProfile,
				params: {
					athleteId: athlete.id,
				}
			});
		}else{
			this.impersonateAthlete(athlete);
		}
	}

	impersonateAthlete(athlete: AthleteProfileModel): void{
		this.$router.push({
			name: this.ImpersonateAthleteProfileRoute,
			params:{
				profileShortId: athlete.shortId,
				athleteId: athlete.id,
			}
		});
	}

}
