var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"horizontal-scroll":""}},[_c('SubscriptionPlanInfoProvider',_vm._b({staticClass:"subscriptions-table-wrapper d-flex",class:{ 'flex-wrap flex-column-reverse': _vm.UseCardView },attrs:{"country":_vm.CustomerCountry},scopedSlots:_vm._u([{key:"default",fn:function({
			state,
			plans,
			PlanPricesFormatted,
			MonthlyPlanPricesFormatted,
			SlashPricesFormatted,
			Ready,
			AllFeatures,
		}){return [(state.IsError)?_c('FailureStateCard',{attrs:{"state":state}}):(!state.IsReady)?_c('v-progress-circular',{staticClass:"ma-auto my-16",attrs:{"indeterminate":"","color":"primary","size":"60"}}):[(_vm.UseCardView)?_vm._l((plans),function(plan,index){return _c('div',{key:plan,staticClass:"d-flex"},[_c('SubscriptionsTablePlan',{staticClass:"mb-6",attrs:{"plans":[plan],"PlanPricesFormatted":[PlanPricesFormatted[index]],"MonthlyPlanPricesFormatted":[MonthlyPlanPricesFormatted[index]],"SlashPricesFormatted":[SlashPricesFormatted[index]],"AllFeatures":AllFeatures,"loading":_vm.getPlanLoading},on:{"click:plan":(plan) => _vm.selectPlan(plan)}})],1)}):[_c('SubscriptionsTablePlan',{attrs:{"plans":plans,"PlanPricesFormatted":PlanPricesFormatted,"MonthlyPlanPricesFormatted":MonthlyPlanPricesFormatted,"SlashPricesFormatted":SlashPricesFormatted,"AllFeatures":AllFeatures,"loading":_vm.getPlanLoading},on:{"click:plan":(plan) => _vm.selectPlan(plan)}})]]]}}])},'SubscriptionPlanInfoProvider',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }