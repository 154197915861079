
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiSoccer, mdiChevronRight } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import { SportName } from '../../../types/enums';

@Component
export default class SportCard extends Mixins(VuetifyMixin){
	mdiChevronRight = mdiChevronRight;
	@Prop({ default: SportName.Soccer }) private sport: SportName;

	get Icon(): string{
		return mdiSoccer;
	}
	get Text(): string{
		return `${this.sport} profile`;
	}
}
