
import { Component, Vue } from 'vue-property-decorator';
import { userStore } from '../store';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import ManageAthleteCard from '../components/profile/ManageAthleteCard.vue';
import { RoleName } from '../../types/enums';
@Component({
	components:{
		ManageAthleteCard,
	}
})
export default class AthleteProfilesManagement extends Vue{

	get Loading(): boolean{
		return !this.AthleteProfilesReady;
	}

	get AthleteProfilesReady(): boolean{
		return userStore.athleteProfilesInitialized && !userStore.addAthleteProfileLoading;
	}
	get MyAthleteProfiles(): AthleteProfileModel[]{
		return userStore.MyAthleteProfiles;
	}
	get AthleteProfile(): AthleteProfileModel{
		return userStore.AthleteProfile;
	}

	myProfile(athlete: AthleteProfileModel): boolean{
		return athlete.isAthlete(userStore.user.id);
	}

	addAthlete(): void{
		userStore.startAthleteOnboarding({
			role: RoleName.Parent,
		});
	}

}
