
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import Dialog from '@/components/ui/Dialog.vue';
import SubscriptionPlanCard from '@/components/subscriptions/SubscriptionPlanCard.vue';
import SubscriptionPlanInfoProvider from '@/components/hoc/SubscriptionPlanInfoProvider.vue';
import SubscriptionsTableCell from '@/components/subscriptions/SubscriptionsTableCell.vue';
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import { PlanFeature } from '@/../types/interfaces';
import { PREMIUM_PLAN_KEY_US } from '@/../types/constants/plan-keys';
import { FeatureFlagMixin } from '@/mixins';

@Component({
	components: { Dialog, SubscriptionPlanInfoProvider, SubscriptionPlanCard, SubscriptionsTableCell }
})
export default class SubscriptionsTablePlan extends Mixins(FeatureFlagMixin, VuetifyMixin){
	@Prop({ required: true }) plans: SubscriptionPlanModel[];
	@Prop({ required: true }) PlanPricesFormatted: string[];
	@Prop({ required: true }) MonthlyPlanPricesFormatted: string[];
	@Prop({ required: true }) SlashPricesFormatted: string[];
	@Prop({ required: true }) AllFeatures: PlanFeature[];
	@Prop({ default: false, type: Boolean}) loading: boolean;

	get UseCardView(): boolean{
		return this.plans.length === 1;
	}
	get UseCardViewIsPopular(): boolean{
		if(this.UseCardView === false) return false;
		return this.plans[0].complete;
	}

	get ProfessionalPlanKeyUS(): string{
		return PREMIUM_PLAN_KEY_US;
	}
	Loading(plan: SubscriptionPlanModel): boolean{
		return this.loading && (this.currentPlan === plan)
	}
	currentPlan = null
	getPlan(plan: SubscriptionPlanModel): void{
		this.currentPlan = plan;
		this.$emit('click:plan', plan)
	}
}
