
import { Component, Mixins } from 'vue-property-decorator';
import { MyAthleteMixin, VuetifyMixin } from '@/mixins';
import SubscriptionsTablePlan from '@/components/subscriptions/SubscriptionsTablePlan.vue';
import MyAthleteProfilesProvider from '@/components/hoc/MyAthleteProfilesProvider.vue';
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import CheckboxCard from '@/components/forms/CheckboxCard.vue';
import { CustomerApi } from '@/api/CustomerApi';
import { AthleteProfileModel } from '@/models';
import { StartCheckoutParams } from '@/../types/interfaces';
import { AthleteCheckoutPage } from '@/../types/constants/web_client_user.routes';
import { AthleteLineItemMeta } from '@/models/checkoutIntent/AthleteLineItemMeta';
import SubscriptionPlanInfoProvider from '@/components/hoc/SubscriptionPlanInfoProvider.vue';
import { notificationStore, userStore } from '@/store';
import { logGAEvent } from '@/plugins/firebase';
import { CustomerCountry } from '@/../types/enums';

@Component({
	components: {
		CheckboxCard,
		SubscriptionPlanInfoProvider,
		SubscriptionsTablePlan,
		MyAthleteProfilesProvider,
	},
	/** Coach Profiles can't upgrade their plan */
	beforeRouteEnter(to, from, next){
		if(userStore.currentProfileData && userStore.currentProfileData.type === 'coach'){
			console.warn("Not upgrading Coach Profile");
			return next('/c');
		}
		return next();
	}
})
export default class UpgradeSubscriptionPage extends Mixins(MyAthleteMixin, VuetifyMixin){
	showProfileDialog: boolean = false;
	get CustomerCountry(): CustomerCountry{
		return userStore.CurrentUserCountry;
	}
	selectPlan(plan: SubscriptionPlanModel): void{
		this.selectedPlan = plan;
		this.upgradeAsAthlete(this.MyAthleteProfile, plan);
	}
	getPlanLoading: boolean = false;
	selectedPlan: SubscriptionPlanModel | null = null;
	async upgradeAsAthlete(athlete: AthleteProfileModel, plan: SubscriptionPlanModel): Promise<void>{
		this.getPlanLoading = true;
		const params: StartCheckoutParams<AthleteLineItemMeta> = {
			title: `Upgrade to ${plan.title} Plan`,
			parentId: athlete.id,
			products:[
				{
					productId: plan.stripeProductId,
					quantity: 1,
					meta: {
						athleteId: athlete.id,
					},
				}
			],
			isSubscription: true,
			automaticTax: true,
			allowCoupons: true,
		};
		logGAEvent("upgrade_plan_selected", { planTitle: plan.title });
		try{
			await CustomerApi.openNewCheckoutSessionUrl('athlete', athlete.id, params);
		}catch(e){
			console.error("Failed to purchase plan", e);
			notificationStore.pushErrorNotificationMessage("Something went wrong, please try again later");
		}finally{
			this.loadingTimeout = setTimeout(() => this.getPlanLoading = false, 10000);
		}
	}

	get UseCardView(): boolean{
		return this.$vuetify.breakpoint.smAndDown
	}

	loadingTimeout: number | null = null;
	beforeDestroy(): void{
		if(this.loadingTimeout){
			clearTimeout(this.loadingTimeout)
		}
	}

}
